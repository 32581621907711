<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3" max-width="500px">
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("accountTree.account")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.accountName"
                      :rules="rules"
                      :label="$t('accountTree.accountName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.parentAccountGuid"
                      :items="accounTreeList"
                      :rules="rules"
                      item-value="guid"
                      item-text="accountDisplayName"
                      :label="$t('accountTree.accountParrentName')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :rules="rules"
                      v-model="editedItem.accountCode"
                      :label="$t('accountTree.accountCode')"
                    >
                      <template slot="append">
                        <v-tooltip top transition="fab-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              rotate
                              v-bind="attrs"
                              v-on="on"
                              @click="generateCode"
                            >
                              mdi-refresh
                            </v-icon>
                          </template>
                          <span>{{ $t("accountTree.autoCode") }}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.note"
                      :label="$t('notes')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-model="editedItem.isDefault"
                      :label="$t('accountTree.isDefault')"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>

      <v-row>
        <v-col>
          <v-sheet class="pa-3">
            <v-text-field
              v-model="search"
              :label="$t('search')"
              flat
              outlined
              hide-details
              dense
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-sheet>
        </v-col>

        <v-col v-if="isInRole('15')" cols="auto" align-self="center">
          <v-tooltip top v-if="isInRole('15')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="addItem"
                color="primary"
                class="mx-1"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider> </v-divider>

      <v-treeview
        v-if="accounTree"
        ref="treeReference"
        :active.sync="active"
        :items="accounTree"
        :open.sync="open"
        :search="search"
        activatable
        open-on-click
        rounded
        item-text="accountDisplayName"
        item-key="guid"
        item-children="children"
      >
        <template v-slot:prepend="{}">
          <v-icon> mdi-folder </v-icon>
        </template>

        <template v-slot:label="{ item }">
          <v-row>
            <v-col align-self="center">
              {{ item.accountDisplayName }}
            </v-col>

            <v-col cols="auto" class="">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-if="isInRole('15')">
                    <v-btn text @click="addItem(item)">
                      <v-list-item-title>{{ $t("add") }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small class="mx-1"> mdi-plus </v-icon>
                      </v-list-item-icon>
                    </v-btn>
                  </v-list-item>

                  <v-list-item v-if="isInRole('16')">
                    <v-btn text @click="editItem(item)">
                      <v-list-item-title>{{ $t("edit") }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small class="mx-1"> mdi-pencil </v-icon>
                      </v-list-item-icon>
                    </v-btn>
                  </v-list-item>

                  <v-list-item v-if="isInRole('17')">
                    <v-btn text @click="deleteItem(item)">
                      <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small class="mx-1"> mdi-delete </v-icon>
                      </v-list-item-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-treeview>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  data() {
    return {
      selected: null,
      active: [],
      open: [],

      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      popupOptions: {
        title: "Employee Info",
        showTitle: true,
        width: 700,
      },
      headers: [
        { text: this.$t("accountTree.accountCode"), value: "accountCode" },
        { text: this.$t("accountTree.accountName"), value: "accountName" },
        { text: this.$t("accountTree.isDefault"), value: "isDefault" },
        { text: this.$t("notes"), value: "note" },

        { text: "", value: "actions" },
      ],
      branches: [],
      accounTreeList: [],
      accounTree: [],
      editedItem: {
        accountCode: "",
        parentAccountGuid: "",
        accountName: "",
        isDefault: false,
        note: "",
      },
      defaultItem: {
        accountCode: "",
        parentAccountGuid: "",
        accountName: "",
        isDefault: false,
        note: "",
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {},

  methods: {
    generateCode() {
      if (
        this.editedItem.parentAccountGuid == undefined ||
        this.editedItem.parentAccountGuid == null ||
        this.editedItem.parentAccountGuid == ""
      ) {
        this.$toast.error("Select Parent Account First!");

        //console.log(this.editedItem.parentAccountGuid);
      } else {
        axios
          .get(
            "AccountTree/GenerateCode?guid=" + this.editedItem.parentAccountGuid
          )
          .then((response) => {
            if (response.data.status == "Successful") {
              this.editedItem.accountCode = response.data.data;
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
    },

    addItem(item) {
      if (item && item.guid) {
        this.editedItem.parentAccountGuid = item.guid;
      } else {
        this.editedItem.parentAccountGuid =
          "00000000-0000-0000-0000-000000000000";
      }
      setTimeout(() => {
        this.generateCode();
        this.dialog = true;
      }, 100);
    },
    editItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.branches[this.editedIndex];

      axios
        .delete("AccountTree/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("AccountTree/Get?includeParent=true")
        .then((response) => {
          this.accounTree = [];
          this.accounTreeList = [];
          this.branches = response.data.data;
          //this.accounTreeList = response.data.data;
          var general = {
            guid: "00000000-0000-0000-0000-000000000000",
            accountName: this.$t("accountTree.generalDirectory"),
            accountDisplayName: this.$t("accountTree.generalDirectory"),
          };
          this.accounTreeList.push(general);
          for (var i = 0; i < this.branches.length; i++) {
            var tree = this.branches[i];
            var children = this.branches.filter((f) => {
              return f.parentAccountGuid == tree.guid;
            });
            if (children.length > 0) {
              children.forEach((child) => {
                var childNode = { ...tree, item: child, vnode: null };
                this.$refs.treeReference.nodes[child.guid] = childNode;
              });
              tree.children = children;
            }
            this.accounTreeList.push(tree);
            if (
              this.branches[i].parentAccountGuid ==
              "00000000-0000-0000-0000-000000000000"
            ) {
              this.accounTree.push(tree);
              // var node = { ...general, item: tree, vnode: null };
              // if (this.$refs.treeReference.nodes[tree.guid])
              //   this.$refs.treeReference.nodes[tree.guid] = node;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.branches[this.editedIndex];
          updatedItem.accountCode = this.editedItem.accountCode;
          updatedItem.parentAccountGuid = this.editedItem.parentAccountGuid;
          updatedItem.accountName = this.editedItem.accountName;
          updatedItem.isDefault = this.editedItem.isDefault;
          updatedItem.note = this.editedItem.note;
          axios
            .post("AccountTree/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.branches[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("AccountTree/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));

              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
